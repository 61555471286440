<template>
    <div v-loading="loading">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header">
                <div class="card-title">
                    <span class="card-icon">
                        <i class="flaticon2-delivery-package text-primary"></i>
                    </span>
                    <h3 class="card-label">
                        <b v-if="type=='create'" class="title-header">承認プロセス設定</b>
                        <b v-else class="title-header">承認プロセス詳細・編集</b>
                    </h3>
                </div>
            </div>
            <div class="card-body">
                <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-position="left" label-width="35%"
                         class="demo-ruleForm  form-base" @keyup.enter.native="isSubmit=true">
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">承認プロセス名<span
                                class="text-danger">*</span></label>
                            <el-form-item prop="approval_name"
                                          class="item-title-form item-fome-base">
                                <el-input class="bg-gray-item" maxlength="40" placeholder="承認プロセス名を入力"
                                          v-model="ruleForm.approval_name"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">承認段階 <span
                                class="text-danger">*</span></label>
                            <el-form-item label="" prop="approval_step"
                                          class="item-title-form item-fome-base">
                                <el-input type="number" :min="1" class="bg-gray-item" placeholder=""
                                          onkeypress="return this.value.length < 1;"
                                          oninput="if(this.value.length>=1) { this.value = this.value.slice(0,1); }"
                                          v-model="ruleForm.approval_step"></el-input>
                                <div v-if="errors.approval_step" class="el-form-item__error">
                                    {{ errors.approval_step[0] }}
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">ステータス <span
                                class="text-danger">*</span></label>
                            <el-form-item class="item-title-form item-fome-base" label=""
                                          prop="approval_status">
                                <el-select class="w--100 bg-gray-item" v-model="ruleForm.approval_status"
                                           placeholder="選択してください">
                                    <el-option
                                        v-for="(item, index) in constants.SATUS_APPROVAL"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">承認ロール1 <span
                                class="text-danger">*</span></label>
                            <el-form-item class="item-title-form item-fome-base" label=""
                                          prop="approval_role1">
                                <el-select class="w--100 bg-gray-item" @change="changeRole" v-model="ruleForm.approval_role1" placeholder="選択してください">
                                    <el-option
                                        v-for="(item, index) in listApproval"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div v-for="index in  checkRole" :key="index" class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">承認ロール{{ index + 1 }} <span
                                class="text-danger">*</span></label>
                            <el-form-item class="item-title-form item-fome-base"
                                          v-bind:prop="'approval_role'+(index+1)">
                                <el-select class="w--100 bg-gray-item" v-model="ruleForm['approval_role'+(index+1)]"
                                           placeholder="選択してください">
                                    <el-option
                                        v-for="(item, index) in listChildApproval"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row mb-8">
                        <div class="col-md-10 col-xl-5">
                            <label class="el-form-item__label" for="">承認階層<span
                                class="text-danger">*</span></label>
                            <el-form-item class="item-title-form item-fome-base" label=""
                                          prop="approval_kaiso">
                                <el-radio :disabled="disable_kaiso" v-for="(data,key) in approvalKaisoType" :key="key" v-model="ruleForm.approval_kaiso" :label="data.value">{{data.label}}</el-radio>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item prop="action" class="button-search" label-width="0px">
                        <el-button class="ml-md-40 pl-10 pr-10" type="primary" @click="isSubmit=true">入力完了</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="submit-form-item">
                <Dialog v-on:closeDialog="isSubmit=false" v-on:submit="create" v-bind:dialog="isSubmit"
                        :closeOnClickModal="false" width="28%" btnOk="はい" :showCancelButton="true" btnCancel="いいえ">
                    <template slot="header">
                        <div class="modal-header text-center">
                            <h4 style="width: 100%;">確認</h4>
                        </div>
                    </template>
                    <template slot="content">
                        <div class="textwidget">
                            <span v-if="type =='create'">{{
                                    $t('DIALOG.CONFIRM_APPROVAL_CREATE', {name: ruleForm.approval_name})
                                }}</span>
                            <span v-else>{{ $t('DIALOG.CONFIRM_APPROVAL_EDIT', {name: ruleForm.approval_name}) }}</span>
                        </div>
                    </template>
                </Dialog>
            </div>
        </div>
        <Popup id="error_message"  :content="messages_error" title_button="閉じる">
        </Popup>
        <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
            <template slot="header">
                <div class="textwidget text-center">
                 {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
                </div>
            </template>
        </my-dialog>
    </div>
</template>
<style lang="scss">
.card-custom {
    border-radius: 15px;
}

.card-custom > .card-header {
    padding: 0;
    margin: 0px 21px;
}

.w--100 {
    width: 100%;
}

.font-label-custom {
    font-weight: bold;
    font-size: 16px;
}
.el-dialog__header{
    padding: 0;
}
</style>

<script>

import * as constants from "@/core/config/constant";
import Dialog from "@/view/components/Dialog";
import {GET_LIST_ROLE_APPROVAL, REGISTER_NEW_APPROVAL, DETAIL_APPROVAL} from "@/core/services/store/approvalRole.module";
import {MAX_STEP_APPROVAL,APPROVAL_KAISO} from "../../../core/config/constant";
import Popup from "@/view/components/Popup";

export default {
    components: {
        Dialog,Popup
    },
    props: {
        type: {
            type: String,
            default: 'create',
        }
    },
    data() {
        return {
            ruleForm: {
                approval_id: '',
                approval_name: '',
                approval_step: 1,
                approval_kaiso: 0,
                approval_role1: '',
                approval_role2: '',
                approval_role3: '',
                approval_role4: '',
                approval_role5: '',
                approval_role6: '',
                approval_role7: '',
                approval_role8: '',
                approval_role9: '',
                approval_status: '',
            },
            rules: {},
            constants: constants,
            errors: [],
            checkShow: {},
            checkRole: '',
            loading: false,
            isSubmit: false,
            listApproval: [],
            listChildApproval: [],
            messages_error:'',
            close_popup: this.$t("NO"),
            not_permisson_popup: false,
            approvalKaisoType:APPROVAL_KAISO,
            disable_kaiso:false
        }
    },
    created() {
        if (this.hasPermissonCheck('kengen_flg')){
            if (this.type === 'edit') {
                this.getDetailApproval()
            }
            this.getListRoleApproval();
        }else {
         this.not_permisson_popup = true
        }
    },
    watch: {
        'ruleForm.approval_step'(newData) { // es6 syntax
            if (newData < 1 || newData > constants.MAX_STEP_APPROVAL) {
                this.showErrorMessage({
                    'approval_step': [this.$t('APPROVAL_STEP_MESSAGHE', {
                        min: 1,
                        max: constants.MAX_STEP_APPROVAL
                    })]
                }, 'ruleForm')
                this.checkRole = 0
            } else {
                this.checkRole = parseInt(newData) - 1
                this.turnOffErrorMessage('ruleForm')
                for (let i = constants.MAX_STEP_APPROVAL; i > parseInt(newData); i--) {
                    this.ruleForm['approval_role' + i] = ''
                }
            }
        },
        listApproval(){
            this.changeRole();
        },
    },
    methods: {
        getListRoleApproval() {
            this.$store.dispatch(GET_LIST_ROLE_APPROVAL).then(data => {
                if (data.code == constants.STATUS_CODE.SUCCESS) {
                    this.listApproval = data.data
                        .filter(roleList => {
                            if (this.type === 'create'){
                                return true;
                            }
                            if (!this.ruleForm.tenant_code){
                                return true;
                            }
                            if (this.type === 'edit' && this.ruleForm.tenant_code){
                                return this.ruleForm.tenant_code === roleList.tenant_code
                            }
                        })
                        .map(roleList => {
                        return {
                            tenant_code: roleList.tenant_code,
                            label: roleList.role_name,
                            value: roleList.role_id
                        }
                    });
                    this.listChildApproval = this.listApproval;
                }
            }).catch(e => {
                this.notifyError(e)
            })
        },
        create() {
            this.isSubmit = false
            this.loading = true
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.turnOffErrorMessage('ruleForm')
                    this.$store.dispatch(REGISTER_NEW_APPROVAL, this.ruleForm).then(data => {
                        this.loading = false
                        if (data && data.data) {
                            if (data.code === constants.STATUS_CODE.SUCCESS) {
                                this.notifySuccess(this.$t('FORM.SETTING.SUCCESS', {form_name: this.ruleForm.form_name}))
                                setTimeout(() => {
                                    this.$router.push({name: "list-approval"})
                                }, 200);
                                return;
                            }
                            if (data.code === constants.STATUS_CODE.BAD_REQUEST) {
                                this.showErrorMessage(data.data, 'ruleForm');
                                if (data.data.approval_status_form){
                                    this.messages_error = this.$t("MESSAGE_ERROR_APPROVAL_ADMIN",{data:data.data.approval_status_form[0]});
                                    this.$bvModal.show("error_message");
                                }
                            } else {
                                this.notifyError(data.message)
                            }
                        } else {
                            this.notifyError(this.$t("ERROR_SERVER"))
                        }
                    }).catch(e => {
                        this.loading = false
                        this.notifyError(e)
                    })
                } else {
                    this.loading = false
                }
            });
        },
        getDetailApproval() {
            this.loading = true
            this.$store.dispatch(DETAIL_APPROVAL, this.$route.params.processId).then(data => {
                this.loading = false
                if (data.code == constants.STATUS_CODE.SUCCESS) {
                    if (!data.data) {
                        this.$router.push({
                            name: 'list-approval',
                        });
                        return;
                    }
                    this.ruleForm = data.data
                }
            }).catch(e => {
                this.loading = false
                this.notifyError(e)
            })
        },
        changeRole(){
            let currentTenant = this.listApproval.find(data => data.value === this.ruleForm.approval_role1);
            if (currentTenant){

            }else{

                this.ruleForm.approval_kaiso = 0;
            }

            this.listChildApproval = this.listApproval.filter(data => {
                if (data && currentTenant && data.tenant_code === currentTenant.tenant_code){
                    return true;
                }
                return false;
            });
            for (let i = 2;i <= 9;i++){
                let indexValue = this.ruleForm['approval_role'+i];
                let checkValue = this.listChildApproval.find(data => data.value === indexValue);
                if (!checkValue){
                    this.ruleForm['approval_role'+i] = '';
                }
            }
        }
    },
}
</script>
